import React from "react"
import { graphql } from "gatsby"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"

const IndexPage = ({ data }) => {
  const { story } = data

  const content = JSON.parse(story.content)

  return (
    <Layout>
      <BodyBlocks body={content.body} />
    </Layout>
  )
}

export const data = graphql`
  {
    story: storyblokEntry(full_slug: { eq: "home" }) {
      name
      content
      full_slug
    }
  }
`

export default IndexPage
